import React from "react"
import Layout from "../components/layout"


export default () => (
< Layout >
    <h2>File Not Found...</h2>
    <img src="images/trump_oscillate.webp" alt="" />
    <br />
    <p>We've got an explorer on your hands...</p> <p>No, you won't find anything all that interesting by poking around.</p>

</ Layout >
)
